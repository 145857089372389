import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Button } from 'react-bootstrap';
import Note from '../components/Note';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Paginate from '../components/Paginate';
import { listNotes, createNote } from '../actions/noteActions';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';

function HomeScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // готовим объекты для заметок
  const notesList = useSelector((state) => state.notesList);
  const { error, loading, notes, page, pages } = notesList;

  // готовим объекты для создания заметки
  const noteCreate = useSelector((state) => state.noteCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    note: createdNote,
  } = noteCreate;

  // готовим объект для токенов пользователя
  const userLogin = useSelector((state) => state.userLogin);
  const { tokens } = userLogin;

  // получаем аргументы из URI
  let [searchParams, setSearchParams] = useSearchParams();
  let pagearg = searchParams.get('page');

  useEffect(() => {
    if (tokens) {
      // если залогинен
      // сбрасываем создание заметки
      dispatch({ type: 'note/create-reset' });

      if (successCreate) {
        // если создали заметку, открываем форму редактирования
        navigate(`/notes/${createdNote.id}/edit`);
      } else {
        // если не создавали, выводим заметки
        dispatch(listNotes(pagearg));
      }
    } else {
      // незалогиненного отправляем на логин
      navigate(`/login?redirect=${location.pathname}`);
    }
  }, [
    dispatch,
    pagearg,
    successCreate,
    createdNote,
    location,
    tokens,
    navigate,
  ]);

  // вызов экшна создания заметки
  const createNoteHandler = () => {
    dispatch(createNote());
  };

  return (
    <div>
      <Button className='my-3' onClick={createNoteHandler}>
        <i className='fas fa-plus'></i> Создать заметку
      </Button>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <div>
          <Row>
            {notes.map((note) => (
              <Note key={note.id} note={note} />
            ))}
          </Row>
          <Paginate page={page} pages={pages} />
        </div>
      )}
    </div>
  );
}

export default HomeScreen;
