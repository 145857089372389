//import axios from 'axios';
import useAxios from '../utils/useAxios';

export const listProjects = () => async (dispatch) => {
  let api = useAxios();

  try {
    dispatch({ type: 'projects/requested' });

    const { data } = await api.get(`/api/projects/`);

    dispatch({
      type: 'projects/listed',
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: 'projects/failed',
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
