export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case 'user/login-requested':
      return { loading: true };

    case 'user/loggedin':
      return { loading: false, tokens: action.payload };

    case 'user/login-failed':
      return { loading: false, error: action.payload };

    case 'user/logout':
      return {};

    case 'user/settokens':
      return { tokens: action.payload };

    default:
      return state;
  }
};
