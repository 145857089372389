import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
//import Note from '../components/Note';
import Loader from '../components/Loader';
import Message from '../components/Message';
import FormContainer from '../components/FormContainer';
import { noteGetDetails, updateNote } from '../actions/noteActions';
import { listProjects } from '../actions/projectActions';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';

function NoteEditScreen() {
  const params = useParams();
  const noteId = params.id;

  const navigate = useNavigate();
  const location = useLocation();

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [stick, setStick] = useState('');
  const [project, setProject] = useState({});

  const dispatch = useDispatch();

  const noteDetails = useSelector((state) => state.noteDetails);
  const { error, loading, note } = noteDetails;

  const noteUpdate = useSelector((state) => state.noteUpdate);
  const {
    error: errorUpdate,
    loading: loadingUpdate,
    success: successUpdate,
  } = noteUpdate;

  const projectsList = useSelector((state) => state.projectsList);
  const {
    error: projectsError,
    loading: projectsLoading,
    projects,
  } = projectsList;

  // готовим объект для токенов пользователя
  const userLogin = useSelector((state) => state.userLogin);
  const { tokens } = userLogin;

  useEffect(() => {
    if (tokens) {
      if (successUpdate) {
        dispatch({ type: 'note/update-reset' });
        navigate(`/notes/${note.id}`);
      } else {
        if (!projects.length) {
          dispatch(listProjects());
        } else {
          if (note.id !== Number(noteId)) {
            dispatch(noteGetDetails(noteId));
          } else if (!note.title) {
            setTitle('');
            setText('');
            setStick(0);
            setProject({});
          } else {
            setTitle(note.title);
            setText(note.text);
            setProject(note.project_detail ? note.project_detail : {});
            setStick('checked' ? note.stick : '');
          }
        }
      }
    } else {
      navigate(`/login?redirect=${location.pathname}`);
    }
  }, [
    dispatch,
    note,
    noteId,
    successUpdate,
    tokens,
    location,
    navigate,
    projects.length,
  ]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateNote({
        id: noteId,
        title,
        text,
        stick: stick ? 1 : 0,
        project: project.id,
      })
    );
  };

  return (
    <div>
      <FormContainer>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : projectsLoading ? (
          <Loader />
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='title'>
              <Form.Label>Название</Form.Label>
              <Form.Control
                type='name'
                placeholder='Название заметки'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='text'>
              <Form.Label>Текст</Form.Label>
              <Form.Control
                as='textarea'
                placeholder='Содержимое'
                value={text}
                onChange={(e) => setText(e.target.value)}
                rows={20}
              ></Form.Control>
            </Form.Group>
            <Form.Group className='mb-3' controlId='stick'>
              <Form.Check
                type='checkbox'
                label='Stick'
                checked={stick}
                onChange={(e) => setStick(e.target.checked)}
              />
            </Form.Group>

            <Form.Group controlId='project'>
              <Form.Label>Проект</Form.Label>
              <Select
                key={project.id}
                name='project'
                defaultValue={{ value: project.id, label: project.name }}
                onChange={(e) => {
                  setProject({ id: e.value, name: e.label });
                }}
                options={projects
                  .map((_project) => ({
                    value: _project.id,
                    label: _project.name,
                  }))
                  .concat({ value: 'null', label: '---' })}
              />
            </Form.Group>

            <Button type='submit' variant='primary'>
              Обновить
            </Button>
          </Form>
        )}
      </FormContainer>
    </div>
  );
}

export default NoteEditScreen;

/*
date = models.DateTimeField('Date Published', auto_now_add=True)
    updated = models.DateTimeField('Date Updated', auto_now=True)
    title = models.CharField(max_length=255)
    text = models.TextField()
    stick = models.BooleanField('Stick at top', default=False)
    public = models.BooleanField('Public', default=False)
    project = models.ForeignKey(Project, models.SET_NULL, null=True)*/

/*<Form.Group controlId='project'>
              <Form.Label>Проект</Form.Label>
              <Select
                name='project'
                defaultValue={{ value: 5, label: '555' }}
                onChange={setProject()}
                options={projects
                  .map((_project) => ({
                    value: _project.id,
                    label: _project.name,
                  }))
                  .concat({ value: 'null', label: '---' })}
              />
            </Form.Group>
            */
