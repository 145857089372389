export const projectListReducer = (state = { projects: [] }, action) => {
  switch (action.type) {
    case 'projects/requested':
      return { loading: true, projects: [] };

    case 'projects/listed':
      return {
        loading: false,
        projects: action.payload,
      };

    case 'projects/failed':
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
