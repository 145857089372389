import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import { Card, Row, Col, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight as ColorTheme } from 'react-syntax-highlighter/dist/esm/styles/prism';

function Note({ note }) {
  return (
    <Card className='my-3 p-3'>
      <Card.Header>
        <Row>
          <Col md={8}>
            <Link to={`/notes/${note.id}`}>
              <strong>{note.title} </strong>
            </Link>
            |{' '}
            <Link to={`/notes/${note.id}/edit`}>
              <small>Редактировать</small>
            </Link>
          </Col>
          <Col md={4}>
            <p className='text-end'>{note.date}</p>
          </Col>
        </Row>
      </Card.Header>

      <Card.Body>
        <ReactMarkdown
          children={note.text}
          remarkPlugins={[remarkGfm, remarkBreaks]}
          components={{
            table: ({ node, ...props }) => (
              <Table bordered striped hover {...props}></Table>
            ),
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || '');
              return !inline && match ? (
                <SyntaxHighlighter
                  children={String(children).replace(/\n$/, '')}
                  style={ColorTheme}
                  language={match[1]}
                  PreTag='div'
                  {...props}
                />
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
          }}
        />
      </Card.Body>

      {note.project && (
        <Card.Footer>
          <p className='text-end'>
            <Link to={`/list?project=${note.project_detail.id}`}>
              {note.project_detail.name}
            </Link>
          </p>
        </Card.Footer>
      )}
    </Card>
  );
}

export default Note;
