import React from 'react';
import { Spinner } from 'react-bootstrap';

function Loader() {
  return (
    <Spinner
      animation='grow'
      role='status'
      variant='primary'
      style={{
        height: '40px',
        width: '40px',
        margin: 'auto',
        display: 'block',
      }}
    >
      <span className='sr-only'>Loading...</span>
    </Spinner>
  );
}

export default Loader;
