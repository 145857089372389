import { Container, Alert } from 'react-bootstrap';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import HomeScreen from './screens/HomeScreen';
import ListNotesScreen from './screens/ListNotesScreen';
import ListProjectsScreen from './screens/ListProjectsScreen';
import NoteDetailsScreen from './screens/NoteDetailsScreen';
import NoteEditScreen from './screens/NoteEditScreen';
import LoginScreen from './screens/LoginScreen';

// {/* <Route path='/projects' element={<PrivateRoute />}>
//               <Route path='/projects' element={<ListProjectsScreen />} />
//             </Route> */}

function App() {
  return (
    <Router>
      <Header />
      <main className='py-3'>
        <Container>
          <Routes>
            <Route path='/' element={<HomeScreen />} exact />
            <Route path='/list' element={<ListNotesScreen />} />
            <Route path='/projects' element={<ListProjectsScreen />} />
            <Route path='/notes/:id' element={<NoteDetailsScreen />} />
            <Route path='/notes/:id/edit' element={<NoteEditScreen />} />
            <Route path='/login' element={<LoginScreen />} />
          </Routes>
        </Container>
      </main>
    </Router>
  );
}

export default App;
