import axios from 'axios';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';
import store from '../store';
import { logout, setTokens } from '../actions/userActions';

const baseURL = '/';

const useAxios = () => {
  // получаем глобальный state
  const state = store.getState();

  let authTokens = state.userLogin.tokens;

  const axiosInstance = axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${authTokens?.access}` },
  });

  axiosInstance.interceptors.request.use(async (req) => {
    // проверяем время жизни токена
    const user = jwt_decode(authTokens.access);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) return req;

    // если токен устарел, делаем запрос с новым refresh токеном
    try {
      const response = await axios.post(`/api/token/refresh/`, {
        refresh: authTokens.refresh,
      });

      // если все нормально, обновляем оба токена
      if (response.status === 200) {
        localStorage.setItem('authTokens', JSON.stringify(response.data));
        store.dispatch(setTokens(response.data));
        req.headers.Authorization = `Bearer ${response.data.access}`;
      }
    } catch (error) {
      // если ошибка - разлогиниваем пользователя
      localStorage.removeItem('tokens');
      store.dispatch(setTokens(null));
      store.dispatch(logout());
      //req.headers.Authorization = '';
      //window.location.href = '/login';
      return Promise.reject(error);
    }

    return req;
  });

  return axiosInstance;
};

export default useAxios;
