export const noteListReducer = (state = { notes: [] }, action) => {
  switch (action.type) {
    case 'notes/requested':
      return { loading: true, notes: [] };

    case 'notes/listed':
      return {
        loading: false,
        notes: action.payload.notes,
        page: action.payload.page,
        pages: action.payload.pages,
      };

    case 'notes/failed':
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const noteDetailsReducer = (state = { note: {} }, action) => {
  switch (action.type) {
    case 'note/requested':
      return { loading: true, ...state };

    case 'note/get':
      return { loading: false, note: action.payload };

    case 'note/failed':
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const noteCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case 'note/creation-requested':
      return { loading: true };

    case 'note/created':
      return { loading: false, success: true, note: action.payload };

    case 'note/creation-failed':
      return { loading: false, error: action.payload };

    case 'note/create-reset':
      return {};

    default:
      return state;
  }
};

export const noteUpdateReducer = (state = { note: {} }, action) => {
  switch (action.type) {
    case 'note/update-requested':
      return { loading: true };

    case 'note/updated':
      return { loading: false, success: true, note: action.payload };

    case 'note/update-failed':
      return { loading: false, error: action.payload };

    case 'note/update-reset':
      return { note: {} };

    default:
      return state;
  }
};
