import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({
      type: 'user/login-requested',
    });

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    const { data } = await axios.post(
      '/api/token/',
      { username: username, password: password },
      config
    );

    dispatch({
      type: 'user/loggedin',
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: 'user/login-failed',
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  console.log('LOGOUT');
  localStorage.removeItem('authTokens');
  dispatch({ type: 'user/logout' });
};

export const setTokens = (tokens) => async (dispatch) => {
  dispatch({ type: 'user/settokens', payload: tokens });
};
