//import axios from 'axios';
import useAxios from '../utils/useAxios';

export const listNotes =
  (page = 1) =>
  async (dispatch) => {
    let api = useAxios();
    try {
      dispatch({ type: 'notes/requested' });

      if (!page) {
        page = 1;
      }

      const { data } = await api.get(`/api/notes/?page=${page}`);

      dispatch({
        type: 'notes/listed',
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: 'notes/failed',
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const listNotesByProject =
  (page = 1, project = 0) =>
  async (dispatch) => {
    let api = useAxios();
    try {
      dispatch({ type: 'notes/requested' });

      if (!page) {
        page = 1;
      }
      if (!project) {
        project = null;
      }

      const { data } = await api.get(
        `/api/notes/?page=${page}&all=1&project=${project}`
      );

      dispatch({
        type: 'notes/listed',
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: 'notes/failed',
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const noteGetDetails = (id) => async (dispatch) => {
  try {
    let api = useAxios();
    dispatch({ type: 'note/requested' });

    const { data } = await api.get(`/api/notes/${id}`);

    dispatch({
      type: 'note/get',
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: 'note/failed',
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createNote = () => async (dispatch, getState) => {
  let api = useAxios();
  try {
    dispatch({
      type: 'note/creation-requested',
    });

    const { data } = await api.post(
      `/api/notes/`,
      {}
      //config
    );
    dispatch({
      type: 'note/created',
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: 'note/creation-failed',
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateNote = (note) => async (dispatch, getState) => {
  let api = useAxios();
  try {
    dispatch({
      type: 'note/update-requested',
    });

    const { data } = await api.put(
      `/api/notes/${note.id}`,
      note
      //config
    );
    dispatch({
      type: 'note/updated',
      payload: data,
    });

    dispatch({
      type: 'note/get',
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: 'note/update-failed',
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
