import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { listProjects } from '../actions/projectActions';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function ListProjectsScreen() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // готовим объекты для списка проектов
  const projectsList = useSelector((state) => state.projectsList);
  const { error, loading, projects } = projectsList;

  // готовим объект для токенов пользователя
  const userLogin = useSelector((state) => state.userLogin);
  const { tokens } = userLogin;

  useEffect(() => {
    if (tokens) {
      // если залогинен, загружаем проекты
      dispatch(listProjects());
    } else {
      // иначе, отправляем на логин
      navigate(`/login?redirect=${location.pathname}`);
    }
  }, [dispatch, navigate, location, tokens]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>id</th>
                <th>Проект</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project) => (
                <tr key={project.id}>
                  <td>{project.id}</td>
                  <td>
                    <Link to={`/list?project=${project.id}`}>
                      {project.name}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
}

export default ListProjectsScreen;
