import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import Message from '../components/Message';
import FormContainer from '../components/FormContainer';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { login } from '../actions/userActions';
import jwt_decode from 'jwt-decode';

function LoginScreen() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [user, setUser] = useState(() =>
    localStorage.getItem('authTokens')
      ? jwt_decode(JSON.parse(localStorage.getItem('authTokens'))['access'])
      : null
  );
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem('authTokens')
      ? JSON.parse(localStorage.getItem('authTokens'))
      : null
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, tokens } = userLogin;

  useEffect(() => {
    if (tokens) {
      setUser(jwt_decode(tokens.access));
      setAuthTokens(tokens);
      localStorage.setItem('authTokens', JSON.stringify(tokens));
      navigate(redirect ? redirect : '/');
    }
  }, [tokens, redirect, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(username, password));
  };

  return (
    <FormContainer>
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}

      <Form onSubmit={submitHandler}>
        <Form.Group controlId='username'>
          <Form.Label>Логин</Form.Label>
          <Form.Control
            type='text'
            placeholder='Login'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='password'>
          <Form.Label>Пароль</Form.Label>
          <Form.Control
            type='password'
            placeholder='Enter Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type='submit' variant='primary'>
          Вход
        </Button>
      </Form>
    </FormContainer>
  );
}

export default LoginScreen;
