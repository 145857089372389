import { useEffect, useState } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { logout, setTokens } from '../actions/userActions';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode';

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [user, setUser] = useState(() =>
    localStorage.getItem('authTokens')
      ? jwt_decode(JSON.parse(localStorage.getItem('authTokens'))['access'])
      : null
  );
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem('authTokens')
      ? JSON.parse(localStorage.getItem('authTokens'))
      : null
  );

  const loggedIn = localStorage.getItem('authTokens') ? true : false;

  useEffect(() => {
    if (localStorage.getItem('authTokens')) {
      dispatch(setTokens(JSON.parse(localStorage.getItem('authTokens'))));
    }
  }, [dispatch]);

  let logoutUser = () => {
    setUser(null);
    setAuthTokens(null);
    localStorage.removeItem('authTokens');
    dispatch({ type: 'user/logout' });
    navigate('/');
  };

  return (
    <header>
      <Navbar bg='primary' variant='dark' expand='lg' collapseOnSelect>
        <Container>
          <LinkContainer to='/'>
            <Navbar.Brand>Notli</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <LinkContainer to='/list'>
              <Nav.Link>
                <i className='fas fa-list'></i> Заметки
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to='/projects'>
              <Nav.Link>
                <i className='fas fa-clone'></i> Проекты
              </Nav.Link>
            </LinkContainer>

            {loggedIn ? (
              <Nav.Link href='/mng'>
                <i className='fas fa-tools'></i> Админка
              </Nav.Link>
            ) : (
              <p></p>
            )}

            {loggedIn ? (
              <Nav.Link onClick={logoutUser}>
                <i className='fas fa-sign-out-alt'></i> Выход
              </Nav.Link>
            ) : (
              <LinkContainer to='/login'>
                <Nav.Link>
                  <i className='fas fa-sign-in-alt'></i> Вход
                </Nav.Link>
              </LinkContainer>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
