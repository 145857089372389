import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Note from '../components/Note';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { noteGetDetails } from '../actions/noteActions';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

function NoteDetailsScreen() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const noteDetails = useSelector((state) => state.noteDetails);
  const { error, loading, note } = noteDetails;

  // готовим объект для токенов пользователя
  const userLogin = useSelector((state) => state.userLogin);
  const { tokens } = userLogin;

  const params = useParams();

  useEffect(() => {
    if (tokens) {
      dispatch(noteGetDetails(params.id));
    } else {
      navigate(`/login?redirect=${location.pathname}`);
    }
  }, [dispatch, params, tokens, location, navigate]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <div>
          <Row>
            <Note key={note.id} note={note} />
          </Row>
        </div>
      )}
    </div>
  );
}

export default NoteDetailsScreen;
