import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import {
  noteListReducer,
  noteDetailsReducer,
  noteCreateReducer,
  noteUpdateReducer,
} from './reducers/noteReducers';
import { projectListReducer } from './reducers/projectReducers';
import { userLoginReducer } from './reducers/userReducers';

const reducer = combineReducers({
  notesList: noteListReducer,
  noteDetails: noteDetailsReducer,
  projectsList: projectListReducer,
  noteCreate: noteCreateReducer,
  noteUpdate: noteUpdateReducer,
  userLogin: userLoginReducer,
});
const initialState = {};
const middleware = [thunk];
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
