import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Paginate from '../components/Paginate';
import { listNotes, listNotesByProject } from '../actions/noteActions';
import {
  useSearchParams,
  useNavigate,
  useLocation,
  Link,
} from 'react-router-dom';

function ListNotesScreen() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const notesList = useSelector((state) => state.notesList);
  const { error, loading, notes, page, pages } = notesList;

  // готовим объект для токенов пользователя
  const userLogin = useSelector((state) => state.userLogin);
  const { tokens } = userLogin;

  //let keyword = history.location.search;
  let [searchParams, setSearchParams] = useSearchParams();
  let pagearg = searchParams.get('page');
  let project = searchParams.get('project');

  useEffect(() => {
    if (tokens) {
      dispatch(listNotesByProject(pagearg, project));
    } else {
      navigate(`/login?redirect=${location.pathname}`);
    }
  }, [dispatch, pagearg, project, tokens, location, navigate]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>id</th>
                <th>Заголовок</th>
                <th>Проект</th>
                <th>Создано</th>
                <th>Обновлено</th>
              </tr>
            </thead>
            <tbody>
              {notes.map((note) => (
                <tr key={`${note.id}`}>
                  <td>{note.id}</td>
                  <td>
                    <Link to={`/notes/${note.id}`}>{note.title}</Link>
                  </td>
                  <td>
                    {note.project && (
                      <Link to={`/list?project=${note.project_detail.id}`}>
                        {note.project_detail.name}
                      </Link>
                    )}
                  </td>
                  <td>{note.date}</td>
                  <td>{note.updated}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Paginate page={page} pages={pages} />
        </div>
      )}
    </div>
  );
}

export default ListNotesScreen;
